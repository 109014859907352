<template>
    <v-container class="container--fluid grid-list-md">
        <v-progress-circular
                :width="10"
                :size="80"
                indeterminate
                v-if="getLoading"
        ></v-progress-circular>
        <v-card class="px-5 pb-5" v-if="!getLoading">
            <v-form v-model="valid" ref="form">
                <div class="d-flex align-center">
                    <v-btn
                            x-small
                            color="primary"
                            outlined
                            dark
                            fab
                            @click="$router.go(-1)"
                            class="mr-3"
                            title="Назад"
                    >
                        <v-icon>mdi-arrow-left-thick</v-icon>
                    </v-btn>
                    <v-btn
                        outlined
                            small
                            color="success"
                            @click="saveCat"
                            class="mx-5"
                    >{{$t('btns.save')}}
                    </v-btn>
                    <v-btn
                            icon
                            dark
                            @click="dialogDelete = !dialogDelete"
                            :title="$t('btns.delete')"
                            color="red"
                            v-if="catId !== 'new'"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-dialog
                            v-model="dialogDelete"
                            width="500"
                    >
                        <v-card class="pa-3">
                            <p class="text-center"><b>{{$t('btns.delete')}}?</b></p>
                            <span>"{{category.name}}"</span>
                            <div class="d-flex justify-center">
                                <v-btn text color="red" @click="deleteCat(category.id)">{{$t('btns.delete')}}</v-btn>
                                <v-btn text color="orange" @click="dialogDelete=false">{{$t('btns.cancel')}}</v-btn>
                            </div>
                        </v-card>
                    </v-dialog>


                    <v-switch
                            class="ml-10"
                            color="success"
                            v-model="category.active"
                            :label='category.active? "Активно": "Неактивно"'
                    ></v-switch>
                </div>
                <v-text-field
                        class="mb-2"
                        label="Название"
                        v-model="category.name"
                        :rules="[rules.required, rules.counter]"
                ></v-text-field>
                <v-row class="mt-5">
                    <v-col cols="12" md="6" lg="6">
                        <div>
                            <div class="d-flex">
                                <p class="p-name">Родительская категория</p>
                                <v-icon
                                        class="mt-md-n1 ml-1"
                                        title="информация"
                                        small
                                >mdi-information
                                </v-icon>
                            </div>
                            <v-select
                                    solo
                                    dense
                            ></v-select>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                        <div>
                            <div class="d-flex">
                                <p class="p-name">Категория Rozetka</p>
                                <v-icon
                                        class="mt-md-n1 ml-1"
                                        title="информация"
                                        small
                                >mdi-information
                                </v-icon>
                            </div>
                            <v-select
                                    solo
                                    dense
                            ></v-select>
                        </div>
                    </v-col>
                </v-row>
                <div class="d-flex justify-space-between mb-3">
                    <h3>Параметры магазина</h3>
                    <div class="d-flex">
                        <v-autocomplete
                                v-model="selectedParams"
                                :items="params"
                                item-value="id"
                                item-text="translations[0].param"
                                class="my-autocomplete"
                                dense
                                solo
                                hide-details="false"
                                label="Выбрать параметр"
                        ></v-autocomplete>
                        <v-btn
                                class="ml-3"
                                color="success"
                                small
                                @click="addParam"
                        >Добавить
                        </v-btn>
                    </div>
                </div>
                <custom-table
                        v-if="paramsCategory"
                        :headers="headers"
                        :items="paramsCategory"
                        height="auto"
                        @send="send"
                />
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "CategoryCard",
        data() {
            return {
                category: {},
                paramsCategory: [],
                params: [],
                selectedParams: null,
                originalCategory: {},
                dialogDelete: false,
                valid: false,
                rules: {
                    required: value => !!value && value.length >= 3 || 'Поле не должно быть быть пустым, не  менее 3 символов',
                    counter: value => !!value && value.length <= 255 || 'Не более 256 символов',
                },
                items: [
                    {
                        id: 456,
                        name: "Вид"
                    }
                ],
                headers: [
                    {
                        text: "ID",
                        value: "id",
                        width: 80,
                        sortable: true,
                        edit: false,
                        search: true,
                    },
                    {
                        text: "Название",
                        value: "param",
                        width: 500,
                        sortable: true,
                        edit: false,
                        search: true,
                        template: 'editName',
                    },
                    {
                        width: 150,
                        sortable: false,
                        edit: false,
                        search: false,
                        template: 'btnParamsEdit',
                    },
                ],
            }
        },
        mounted() {
            if (this.catId !== 'new') {
                this.createCategoryCard()
            }
            this.initParamsCats().then(function () {
                this.params = this.getParamsCats.items
            }.bind(this))
        },
        computed: {
            ...mapGetters(["getCurrentCategory", "getLoading", "getParamsCats"]),
            catId() {
                return this.$route.params.id;
            },
        },
        watch: {
            catId() {
                if (this.catId === 'new') {
                    this.category = {}
                }
            },
        },
        methods: {
            ...mapActions(["currentCategory", "initParamsCats", "saveCategory", "deleteCategory", "updateCategory", "addParamsCats", "deleteParamsCats"]),
            send(e) {
                if (e.func === 'delete') {
                    this.delParamsCats(e.id)
                }
            },
            deepEqual(obj1, obj2) {
                return JSON.stringify(obj1) === JSON.stringify(obj2)
            },
            createCategoryCard() {
                this.currentCategory(this.catId).then(function () {
                    this.getApiCategory()
                    this.originalCategory = Object.assign({}, this.category);
                }.bind(this))
            },
            getApiCategory() {
                let response = this.getCurrentCategory
                this.category = {
                    ...response.translations[0],
                    ...response
                }
                this.paramsCategory = []
                for (let p of response.params) {
                    this.paramsCategory.push(
                        {
                            ...p.translations[0],
                            ...p
                        }
                    )
                }
                console.log(response.params)
            },
            saveCat() {
                if (this.catId === 'new') {
                    if (this.$refs.form.validate()) {
                        this.saveCategory(this.category).then(function (response) {
                            console.log(response)
                            if (response.status === 1) {
                                console.log(response.data.id)
                                this.$router.push(`/category/${response.data.id}`)
                                this.createCategoryCard()
                            }
                        }.bind(this))

                    }
                } else {
                    let equally = this.deepEqual(this.category, this.originalCategory)
                    if (equally === false) {
                        if (this.$refs.form.validate()) {
                            let data = {
                                id: this.catId,
                                category: this.category
                            }
                            this.updateCategory(this.category).then(function () {
                                this.createCategoryCard()
                            }.bind(this))

                        }
                    }
                }
            },
            deleteCat(id) {
                this.deleteCategory(id).then(function (response) {
                    if (response.status === 1) {
                        this.dialogDelete = false
                        this.$router.go(-1)
                    } else {
                        console.log("error")
                    }
                }.bind(this))

            },
            addParam() {
                let data = {
                    'CatalogParam': {'id': this.selectedParams},
                    'CatalogParamToCat': [{'cid': this.catId}]
                }
                this.addParamsCats(data).then(function (response) {
                    if (response.status === 1) {
                        this.selectedParams = null
                        this.createCategoryCard()
                    } else console.log('error')

                }.bind(this))

            },
            delParamsCats(id) {
                let value = this.category.paramsLinks.find(cat => cat.paramid === id)
                this.deleteParamsCats(value.id).then(function (response) {
                    if (response.status === 1) {
                        this.createCategoryCard()
                    } else console.log('error')
                }.bind(this))
            },
        }
    }
</script>

<style>
    .dt-header {
        background-color: #fafafa !important;
    }

    .my-autocomplete > .v-input__control {
        min-height: 28px !important;
    }
</style>
